html,
body {
  margin: 0;
  padding: 0;
  background: #FFF;
  color: @text-color;
  font-size: 14px;
}

a, a:hover {
  color: @text-color;
  text-decoration: none;
}

.page {
  &-wrapper {
    width: 100%;
    height: 100vh;
  }
  &-left-view {
    float: left;
    width: 40%;
    height: 100%;
    background-color: @mouse-catcher-color;
  }
  &-right-view {
    display: flex;
    justify-content: left;
    align-items: center;
    width: ~"calc(100% - 40% - 60px)";
    height: 100%;
    padding-left: 60px;

    h1 {
      padding: 0;
      margin: 0;
      font-size: 28px;
    }

    .form-item {
      margin: 35px 0 0;
      .title {
        width: 100%;
        padding: 0;
        margin: 0 0 7px;
        font-size: 16px;
        >div {
          float: right;
        }
        >span, >i, >mark {
          font-size: 12px;
        }
        >span {
          float: right;
        }
        >i {
          font-style: normal;
          color: #DDD;
        }
        >mark {
          margin-left: 8px;
          padding: 2px 6px;
          background-color: @highlight-href-color;
          color: #FFF;
        }
      }
      .tip {
        display: inline;
        margin-left: 15px;
        color: @tip-text-color;
        font-size: 12px;
        a {
          color: @tip-text-color;
          &:hover {
            color: @mouse-catcher-color;
          }
        }
      }
      .radio {
        padding-left: 25px;
        padding-right: 25px;
      }
      .price {
        color: #43AE49;
        span {
          margin-right: 10px;
          color: @highlight-href-color;
          font-size: 30px;
        }
      }
      &-submit {
        width: 170px;
        height: 37px;
        font-size: 16px;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .page {
    &-wrapper, &-left-view, &-right-view {
      width: 100%;
      height: auto;
    }
    &-right-view {
      padding: 40px 20px 20px;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;      
      .form-item {
        .tip {
          display: block;
          margin-left: 0;
          line-height: 45px;
        }
        .radio {
          padding-left: 20px;
          padding-right: 20px;
        }
        &-submit {
          display: block;
          margin: 0 auto;
          width: 90%;
        }
      }
    }
  }
}