.pay {
  width: 100%;
  .title {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F0F0F0;
    text-align: right;
    .return {
      float: left;
      line-height: 18px;
      font-size: 18px;
      color: @tip-text-color;
      cursor: pointer;
      i {
        margin-left: 5px;
        font-size: 16px;
        font-style: normal;
      }
    }
  }
  .step1, .step2 {
    display: inline-block;
    width: 100%;
    padding: 10px 0 15px;
  }
  .step1 {
    color: @tip-text-color;
    img {
      float: left;
      width: 200px;
      height: 200px;
      margin-right: 20px;
      border: 10px solid #FFF;
    }
    .content {
      display: inline-block;
      width: 370px;
      margin-top: 15px;
      padding-top: 15px;
      font-size: 12px;
      border-top: 1px solid #F0F0F0;
    }
    .price {
      padding-top: 5px;
      color: @text-color;
      font-size: 16px;
      span {
        color: @highlight-href-color;
        font-size: 30px;
      }
    }
  }
  .step2 {
    line-height: 40px;
    .input {
      margin: 10px 0;
      width: 60%;
      height: 40px;
    }
    span {
      color: @text-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .button {
    margin-right: 15px;
    height: 37px;
    font-size: 16px;
    border-radius: 5px;
  }
  &-contact {
    padding: 10px;
    line-height: 25px;
    text-align: center;
    img {
      margin-bottom: 12px;
      max-width: 150px;
    }
  }
}

@media screen and (max-width: 767px) {
  .pay {
    .title {
      span:last-child {
        clear: both;
        display: block;
        width: 100%;
        padding-top: 15px;
        text-align: left;
      }
    }
    .step1 {
      .content {
        clear: both;
        display: block;
        width: auto;
      }
      .price {
        clear: both;
        padding-top: 15px;
      }
    }
    .step2 {
      .input {
        width: 90%;
      }
    }
    .button {
      display: block;
      margin: 0 auto 20px;
      width: 90%;
    }
  }
}