.banner {
  padding: 30px 30px 10px;
  height: 100%;
  background: url(http://img.yqlizhi.com/buy/banner-bg.svg) top right no-repeat;
  background-size: auto 100%;
  .logo {
    display: inline-block;
    height: 35px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .link {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    text-align: right;
    a {
      color: #FFF;
      &:hover {
        color: @highlight-href-color;
      }
    }
  }
  &-content {
    display: flex;
    justify-content: left;
    align-items: center;
    height: ~"calc(100% - 25px - 50px)";
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .banner {
    &-content {
      img {
        margin: 0 7%;
        width: 86%;
      }
    }
  }
}